import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '@core/authentication/services/authentication.service';

export const depositKeyGuard: CanActivateFn = (route) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);

  const depositKey =
    authenticationService.getTeledepositToken() ?? route.queryParams.depositKey;
  if (!depositKey) {
    router.navigate(['/', 'deposit-token-error']);
    return false;
  }

  authenticationService.setTeledepositToken(depositKey);
  return true;
};
