import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HelpActionsService } from '@core/services/help-actions.service';
import { MenuActionsComponent } from '@shared/components/menu-actions/menu-actions.component';
import { TuiButton, TuiDropdown } from '@taiga-ui/core';
@Component({
  selector: 'pxc-header',
  standalone: true,
  imports: [TuiButton, TuiDropdown, MenuActionsComponent],
  providers: [HelpActionsService],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  helpActions = inject(HelpActionsService).getActions();
  readonly title = inject(Title).getTitle();
}
