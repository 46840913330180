<div class="flex flex-col gap-2 py-2">
  <pxc-title [title]="'Bonjour,'"></pxc-title>
  <p class="max-w-prose text-balance text-slate-600 text-sm">
    Connectez-vous à votre environnement sécurisé de photographies médicales.
  </p>
</div>

<form
  [formGroup]="loginForm"
  (ngSubmit)="login()"
  data-testid="login-form"
  class="flex flex-col gap-4 mt-6"
>
  @if (inputPassword) {
    <div>
      <tui-input-password
        formControlName="inputPassword"
        tuiTextfieldIconLeft="tuiIconLockLarge"
        [disabled]="(actionOnGoing$ | async) ? true : false"
      >
        Mot de passe
      </tui-input-password>
    </div>
  }

  <div class="flex flex-row-reverse justify-between flex-wrap gap-3 mt-4">
    <button
      tuiButton
      type="submit"
      appearance="primary"
      [disabled]="actionOnGoing$ | async"
      [loading]="actionOnGoing$ | async"
      data-testid="login-button"
      class="max-sm:order-first max-sm:w-full"
    >
      Se connecter
    </button>
  </div>
</form>
