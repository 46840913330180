import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private bearerToken?: string;
  private teledepositToken?: string;

  public setBearerToken(bearerToken: string): void {
    this.bearerToken = bearerToken;
  }

  public getBearerToken(): string | undefined {
    return this.bearerToken;
  }

  public setTeledepositToken(teledepositToken: string): void {
    this.teledepositToken = teledepositToken;
  }

  public getTeledepositToken(): string | undefined {
    return this.teledepositToken;
  }

  public clear() {
    this.bearerToken = undefined;
    this.teledepositToken = undefined;
  }
}
