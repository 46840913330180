<main
  class="overflow-x-hidden min-h-screen transition animate-in fade-in bg-slate-50 container flex flex-col justify-center items-center"
>
  <div
    class="absolute blur-3xl bg-pixablue-2/20 w-64 h-full max-h-80 rounded-full -translate-x-40 -translate-y-48"
  ></div>
  <div
    class="absolute blur-3xl bg-pixapurple-2/20 rounded-full w-64 h-full max-h-80 translate-x-48"
  ></div>
  <div
    class="absolute blur-3xl bg-pixared-2/20 w-full max-w-sm h-64 rounded-full translate-y-40"
  ></div>

  <div
    tuiCardLarge
    tuiSurface="flat"
    class="max-w-lg w-full animate-in fade-in slide-in-from-top-1 !bg-white/90 backdrop-blur-lg"
  >
    <!-- TODO : Use the teledeposit app logo or the telemonitoring logo -->
    <img
      src="https://cdn.pixacare.com/logo/left-transparent.svg"
      data-testid="logo"
      class="w-40"
      alt="Pixacare Logo"
    />
    <router-outlet data-testid="content"></router-outlet>
  </div>
</main>
